import { createMuiTheme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

export default createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: orange[500],
            light: orange[200],
            dark: orange[800],
            contrastText: '#fff',
        },
        text: {
            secondary: orange[500],
        },
        background: {
            paper: '#424242',
            default: '#303030',
        },
    },
    overrides: {
        MuiContainer: {
            root: {
                disableGutters: true,
            },
        },
    },
});

import React from 'react';
import { Grid } from '@material-ui/core';

import Logo from './Logo';

const Logos = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <Logo
                    lang="es"
                    headerTitle="Bienvenido al sitio web oficial del HDC España"
                    mediaSrc="hdc_espana.png"
                    mediaTitle="HDC España"
                    content1="El HDC España está compuesto por clubes HDC de toda la geografía Española."
                    content2="Pertenecemos, a su vez, a la Federación Europea de Clubes Harley-Davidson (FH-DCE)."
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Logo
                    lang="eu"
                    headerTitle="Welcome to the official website of the HDC España (Spain)"
                    mediaSrc="fe_hdc_europe.png"
                    mediaTitle="HDC Spain"
                    content1="HDC Spain is made up of HDC from the entire Spanish geography."
                    content2="We belong, at the same time, to the Federation Harley-Davidson Clubs Europe (FH-DCE)."
                />
            </Grid>
        </Grid>
    );
};

export default Logos;

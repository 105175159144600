import React, { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';

import Logos from '../components/Logos';

const LandingPage = () => {
    return (
        <>
            <Box my={3}>
                <Typography variant="h4" color="textSecondary" align="center">
                    HDC España
                </Typography>
                <Typography variant="h6" color="textSecondary" align="center">
                    (anteriormente[formerly] Federación Española de Harley
                    Riders)
                </Typography>
            </Box>
            <Logos />
            <Box my={3} align="center">
                <Button
                    variant="contained"
                    startIcon={<FacebookIcon color="action" />}
                    color="primary"
                    onClick={() =>
                        window.open(
                            'https://www.facebook.com/feharleyriders',
                            '_blank'
                        )
                    }
                >
                    Síguenos en Facebook
                </Button>
            </Box>
        </>
    );
};

export default LandingPage;

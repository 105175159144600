import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import hdcTheme from './styles/hdcTheme';
import LandingPage from './pages/LandingPage';

function App() {
    return (
        <ThemeProvider theme={hdcTheme}>
            <CssBaseline />
            <Container maxWidth="md">
                <LandingPage />
            </Container>
        </ThemeProvider>
    );
}

export default App;

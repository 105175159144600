import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardMedia,
    Avatar,
    Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
    img: {
        height: '16rem',
    },
    content: {
        height: '10rem',
    },
});

const Logo = (props) => {
    const classes = useStyles();
    const Flag = (props) => (
        <Avatar
            alt={props.lang === 'es' ? 'Spanish' : 'English'}
            src={`/static/images/${props.lang}.png`}
        />
    );

    return (
        <Card elevation={2}>
            <CardHeader
                avatar={<Flag lang={props.lang} />}
                title={props.headerTitle}
            />
            <CardMedia
                src={`/static/images/${props.mediaSrc}`}
                title={props.mediaTitle}
                align="center"
            >
                <img
                    className={classes.img}
                    src={`/static/images/${props.mediaSrc}`}
                    alt={props.mediaTitle}
                />
            </CardMedia>
            <CardContent className={classes.content}>
                <Typography variant="body1">{props.content1}</Typography>
                <Typography variant="body1">{props.content2}</Typography>
            </CardContent>
        </Card>
    );
};

export default Logo;
